/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-03-13 10:54:47
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-03-16 19:42:37
 */
export default [
  // --------集团报表----------- //
  {
    // 集团报表-home
    path: '/groupreport/home',
    name: 'groupreport_home',
    meta: {
      // 可选
      // permissionCode: 'cp-hotel-memberCouponUse',
      // 1: 平台 2: 集团 3: 酒店
      permissionCode: 'cp-group-groupreport',
      routerType: 2,
    },
    component: () => import('../views/home/home.vue'),
  },

];
